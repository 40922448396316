export const formatName = (name: string) => {
  const wordsLink = ['de', 'dos', 'da', 'do', 'das']

  return name
    .toLowerCase()
    .split(' ')
    .map((palavra, index) => {
      if (wordsLink.includes(palavra) && index !== 0) {
        return palavra
      }
      return palavra.charAt(0).toUpperCase() + palavra.slice(1)
    })
    .join(' ')
}
