import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const CardSliderResumeContent = styled.div<{
  bgColor: string
  isTrailGenerated: boolean
  itemsPerPage: number
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: ${({ isTrailGenerated }) => (isTrailGenerated ? '250px' : '264px')};
  max-width: ${({ itemsPerPage }) => (itemsPerPage === 4 ? '207.2px' : 'auto')};
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  justify-content: space-between;
  line-height: 100%;
  padding: 16px;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ isTrailGenerated, bgColor, theme }) => (isTrailGenerated ? bgColor : theme.colors.midLight)};
  border-radius: 8px;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ContentNumber = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ theme }) => theme.colors.midDark};
  z-index: 1;
`
export const StepNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardIndex = styled.div<{
  color: string
}>`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  display: flex;
  border-radius: 24px;
  background-color: ${({ color }) => color};
`

export const CardTime = styled.div<{
  color: string
}>`
  color: ${({ color }) => color};
  font-size: 14px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 700;
`

export const CardStatus = styled.div<{
  color: string
}>`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 100%;
  }
`
export const TextSpan = styled.span``

export const CardToolTip = styled.div`
  display: flex;
  margin-left: -12px;
`
export const TitleCard = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 72px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.lowPure};
`
export const ProfessorContent = styled.div`
  display: flex;
`
export const TitleLessonCard = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  min-width: fit-content;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.lowPure};
`
export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const TextInfoLabel = styledMUI(Typography)(() => ({
  fontSize: '14px',
  color: `${theme.colors.lowPure}`,
  fontWeight: 700,
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  span: {
    color: `${theme.colors.lowLight}`,
    fontSize: '14px',
    fontWeight: 400,
  },
}))

export const BtnExplorar = styled.button`
  display: flex;
  height: 24px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.actionPure};
  color: ${({ theme }) => theme.colors.actionPure};
  background-color: transparent;
  transition: background 0.3s, color 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.actionPure};
    color: ${({ theme }) => theme.colors.highPure};
  }
`
